<template>
  <section class="head">
    <div class="bg"></div>
    <div class="content" v-if="Object.keys(dataInfo).length > 0">
      <div class="cover">
        <img :src="dataInfo.coverImg" alt="" />
      </div>
      <div class="text">
        <h1>{{ dataInfo.courseName }}</h1>
        <template v-if="dataInfo.openLeave == 1">
          <a-button
            type="primary"
            danger
            class="leave red"
            v-if="dataInfo.isLeave <= 0"
          >
            <template #icon><i class="icon-qj"></i></template>
            {{ $t("train.ask_for_leave") }}
            <!-- 请假 -->
          </a-button>
          <a-button
            type="primary"
            danger
            class="leave hui"
            disabled
            v-else-if="dataInfo.isLeave == 2"
          >
            {{ $t("train.approving_for_leave") }}
            <!-- 请假审批中 -->
          </a-button>
          <a-button
            type="primary"
            danger
            class="leave white"
            v-else-if="dataInfo.isLeave == 1"
          >
            <template #icon><i class="icon-qj"></i></template>
            {{ $t("train.cancel_leave") }}
            <!-- 取消请假 -->
          </a-button>
        </template>
        <p>
          <a-rate v-model:value="dataInfo.avgSecScore" allow-half disabled />
          <em
            >{{ dataInfo.avgSecScore.toFixed(1) }}
            {{ $t("LB_Evaluate_Score") }}</em
          >
          <!-- 分 -->
        </p>
        <p class="duration">
          <span class="span"
            >{{ $t("train.lesson_hours") }}：{{ dataInfo.courseTimes }}</span
          >
          <span
            class="span"
            v-if="dataInfo.trainLecturers && dataInfo.trainLecturers.length"
          >
            <!-- 讲师： -->
            {{ $t("XB_Course_Lecturer") }}：<template
              v-for="(item, index) in dataInfo.trainLecturers"
              :key="index"
            >
              <OpenData
                type="userName"
                :openid="item.lecturerName"
                v-if="item.lecturerType == 1"
              /><span v-else>{{ item.lecturerName }}</span
              >{{ index + 1 == dataInfo.trainLecturers.length ? "" : "、" }}
            </template>
          </span>
          <span class="span" v-else>
            <!-- 讲师： -->
            {{ $t("XB_Course_Lecturer") }}：<OpenData
              type="userName"
              :openid="dataInfo.lecturerName"
              v-if="dataInfo.lecturerType == 1"
            /><span v-else>{{ dataInfo.lecturerName }}</span>
          </span>
        </p>
        <div class="box">
          <p class="time">
            <ClockCircleOutlined />
            {{ moment(dataInfo.trainTime[0]).format("YYYY-MM-DD HH:mm") }} ~
            {{ moment(dataInfo.trainTime[1]).format("YYYY-MM-DD HH:mm") }}
          </p>
          <p class="address">
            {{ $t("train.class_place") }}：{{ dataInfo.address }}
          </p>
          <!-- 上课地点 -->
          <!-- 考勤地址 -->
          <p class="address" v-if="dataInfo.locationAddress">
            {{ $t("AttendanceAddress") }}：{{ dataInfo.locationAddress }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="mian" v-if="Object.keys(dataInfo).length > 0">
    <a-spin :spinning="loadding">
      <div class="detail">
        <a-tabs v-model:activeKey="activeKey">
          <!-- 考勤 -->
          <a-tab-pane key="1" :tab="$t('train.attendance')">
            <clocking :data="dataInfo" :taskId="taskId" :detailId="did" />
          </a-tab-pane>
          <!-- 评价学员 -->
          <a-tab-pane
            key="2"
            :tab="$t('teaching.evaluate_students')"
            v-if="dataInfo.lesId"
          >
            <grade :data="dataInfo" :taskId="taskId" :detailId="did" />
          </a-tab-pane>
          <!-- 线下成绩 -->
          <a-tab-pane
            key="3"
            :tab="$t('train.offline_grades')"
            v-if="dataInfo.openExam == 1"
          >
            <score :data="dataInfo" :taskId="taskId" :detailId="did" />
          </a-tab-pane>
          <!-- 活动附件 -->
          <a-tab-pane
            key="4"
            :tab="$t('train.activity_attachments')"
            v-if="dataInfo.openUpload == 1"
          >
            <attachment :data="dataInfo" :taskId="taskId" :detailId="did" />
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>

    <div class="cards">
      <div class="item card-1">
        <h2 class="title">{{ $t("train.attendance_info") }}</h2>
        <!-- 考勤信息 -->
        <div class="item-part">
          <h5>{{ $t("train.sign_in_time") }}：</h5>
          <!-- 签到时间： -->
          <p>
            {{ dateFormat(dataInfo.inStartTime) }} -
            {{ dateFormat(dataInfo.inEndTime) }}
          </p>
        </div>
        <div class="item-part">
          <h5>{{ $t("train.sign_out_time") }}：</h5>
          <!-- 签退时间： -->
          <p>
            {{ dateFormat(dataInfo.outStartTime) }} -
            {{ dateFormat(dataInfo.outEndTime) }}
          </p>
        </div>
        <template v-if="isShowMeet && dataInfo.trainType > 1">
          <div
            class="qr-btn"
            v-if="platformConfig.platform == 26"
            @click="goWxMeet(dataInfo.meetId)"
          >
            {{ $t("train.enter_meeting") }}
            <!-- 进入会议 -->
          </div>
          <div
            class="qr-btn"
            v-if="
              platformConfig.platform == 31 || platformConfig.platform == 30
            "
            @click="goDingMeet(dataInfo.meetData)"
          >
            {{ $t("train.enter_meeting") }}
            <!-- 进入会议 -->
          </div>
        </template>
        <div class="qr-btn" @click="showQr('inQr')">
          {{ $t("train.sign_in_qr") }}
        </div>
        <!-- 签到二维码 -->
        <div class="qr-btn" @click="showQr('outQr')">
          {{ $t("train.sign_out_qr") }}
        </div>
        <!-- 签退二维码 -->
      </div>
      <div class="item card-2" v-show="activeKey == '3'">
        <h2 class="title">{{ $t("train.grade_infor") }}</h2>
        <!-- 成绩信息 -->
        <div class="item-part">
          <h5>{{ $t("homework.full_score") }}：</h5>
          <!-- 满分： -->
          <p>
            <i>{{ dataInfo.examScore }}</i> {{ $t("exam.score") }}
            <!-- 分 -->
          </p>
        </div>
        <div class="item-part">
          <h5>{{ $t("train.pass_score") }}：</h5>
          <!-- 及格分： -->
          <p>
            <i>{{ dataInfo.examPass }}</i> {{ $t("exam.score") }}
            <!-- 分 -->
          </p>
        </div>
        <div class="item-part">
          <h5>{{ $t("train.entered") }}：</h5>
          <!-- 已录入： -->
          <p>
            <i>{{ scoreNum }}</i
            >{{ $t("subject.people") }}
            <!-- 人 -->
          </p>
        </div>
        <div class="item-part">
          <h5>{{ $t("train.not_entered") }}：</h5>
          <!-- 未录入： -->
          <p>
            <i>{{ notScoreNum }}</i
            >{{ $t("subject.people") }}
            <!-- 人 -->
          </p>
        </div>
      </div>
    </div>
  </section>
  <a-modal
    v-model:visible="modalVisible"
    :title="modalData[modalType] ? modalData[modalType].title : ''"
    width="500px"
    :footer="null"
    @cancel="QrModalCancel"
  >
    <div class="qrcode-box">
      <div id="qrcodeWrap">
        <div id="qrcode" class="qrcode"></div>
      </div>
      <p v-if="qrcodeInfo.dynamic > 0">
        {{ $t("train.dynamic_q_r_code", [10]) }}
      </p>
      <!-- 动态二维码，每10秒刷新一次 -->
      <p v-else>
        {{ $t("XB_FixedQRCode") }}
        <!-- 固定二维码 -->
        <span class="download" @click="clickDownload">
          <DownloadOutlined />
          {{ $t("cm_download") }}
          <!-- 下载 -->
        </span>
      </p>
    </div>
  </a-modal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  ref,
  reactive,
  provide,
  toRefs,
  nextTick,
  getCurrentInstance,
  computed,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { dateFormat, fileDownload } from "@/utils/tools";
import { trainDetail } from "@/api/train";
import moment from "moment";
import clocking from "./components/clocking";
import grade from "./components/grade";
import score from "./components/score";
import attachment from "./components/attachment";
import { qrcodeNew } from "@/api/qrcode";
import QRCode from "qrcodejs2";
import { currentHost } from "@/utils/request";
import OpenData from "@/components/OpenData.vue";
import wxwork from "@/utils/wxwork";

export default {
  components: {
    clocking,
    grade,
    score,
    attachment,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const taskId = Number(route.query.id);
    const did = Number(route.query.did);

    const state = reactive({
      activeKey: "1",
      rateValue: 0,
      modalType: "",
      modalVisible: false,
      modalData: {
        inQr: {
          title: $t("train.sign_in_qr"),
          // 签到二维码
        },
        outQr: {
          title: $t("train.sign_out_qr"),
          // 签退二维码
        },
      },
      scoreNum: 0,
      notScoreNum: 0,
      qrcodeInfo: {},
      timer: null,
      isShowMeet: true,
    });

    const platformConfig = computed(() => store.getters.platformConfig);

    let loadding = ref(true),
      dataInfo = ref({});
    trainDetail(taskId, did).then((res) => {
      loadding.value = false;
      if (res.ret === 0) {
        // 讲师
        if (res.data.trainLecturers && res.data.trainLecturers.length) {
          res.data.lecturerUid = res.data.trainLecturers[0].lecturerUid;
          res.data.LecturerName = res.data.trainLecturers[0].lecturerName;
          res.data.lecturerType = res.data.trainLecturers[0].lecturerType;
          res.data.lecturerAvatar = res.data.trainLecturers[0].lecturerAvatar;
        }

        let now = new Date().getTime();
        let endTime = new Date(
          res.data.trainTime[1].replace(/-/g, "/")
        ).getTime();
        state.isShowMeet = now < endTime;

        dataInfo.value = res.data;
        // if (
        //   dataInfo.value.attachmentList &&
        //   dataInfo.value.attachmentList.length > 0
        // ) {
        //   dataInfo.value.attachmentList.map((item) => {
        //     if (item.fileType == 1) {
        //       item.filePath =
        //         item.filePath.slice(0, item.filePath.lastIndexOf(".")) +
        //         "/1/mp4/1_1.mp4";
        //     }
        //   });
        // }
        // 活动附件各类型总数
        dataInfo.value.trainAttachmentType = [0, 0, 0];
        if (
          dataInfo.value.trainAttachmentList &&
          dataInfo.value.trainAttachmentList.length > 0
        ) {
          dataInfo.value.trainAttachmentList.map((item) => {
            if (item.fileType == 4) {
              dataInfo.value.trainAttachmentType[0] += 1;
            } else if (item.fileType == 1) {
              // item.filePath =
              //   item.filePath.slice(0, item.filePath.lastIndexOf(".")) +
              //   "/1/mp4/1_1.mp4";
              dataInfo.value.trainAttachmentType[1] += 1;
            } else {
              dataInfo.value.trainAttachmentType[2] += 1;
            }
          });
        }

        if (wxwork.isWxWork) {
          wxwork.agentConfig();
        }
      } else {
        router.replace({
          path: `/error`,
          query: {
            title: $t("train.no_exist"),
            // 抱歉，您访问的面授不存在。
            path: "/",
            name: $t("CM_Index"),
            // 首页
          },
        });
      }
    });

    const showQr = (type) => {
      state.modalType = type;
      createQRCode();
    };

    const createQRCode = () => {
      qrcodeNew({
        taskId: taskId,
        detailId: did,
        resourceType: state.modalType == "inQr" ? 15 : 16, //资源类型 15签到 16签退 9直播
      }).then((res) => {
        state.modalVisible = true;
        if (res.ret === 0) {
          state.qrcodeInfo = res.data;

          nextTick(() => {
            newQRCode(state.modalType);
          });

          clearTimeout(state.timer);
          if (res.data.dynamic > 0) {
            state.timer = setTimeout(() => {
              createQRCode();
            }, (res.data.dynamic + 1) * 1000);
          }
        }
      });
    };

    const QrModalCancel = () => {
      clearTimeout(state.timer);
    };

    const newQRCode = (type) => {
      let domain = "";
      let mobileDomain =
        platformConfig.value.mobileDomain ||
        platformConfig.value.customMobileDomain;
      if (mobileDomain) {
        domain = mobileDomain;
      } else {
        let host = "";
        currentHost.split(".").map((item, index) => {
          if (index === 0) {
            host += item.replace("-pc", "") + "-m";
          } else {
            host += item;
          }
          if (index + 1 < currentHost.split(".").length) {
            host += ".";
          }
        });
        domain = host;
      }
      let textUrl = `${location.protocol}//${domain}/view/qrcode/index?code=${
        state.qrcodeInfo.code
      }&resourceType=${state.modalType == "inQr" ? 15 : 16}`;
      let qrcodeEl = document.getElementById("qrcode");
      qrcodeEl.innerHTML = "";
      new QRCode("qrcode", {
        width: 150,
        height: 150,
        text: textUrl,
        colorDark: "#000",
        colorLight: "#fff",
      });

      let logo = new Image();
      logo.setAttribute("crossOrigin", "Anonymous");
      if (type == "inQr") {
        logo.src = require("../../../assets/image/qr-sign-in.jpg");
      } else {
        logo.src = require("../../../assets/image/qr-sign-out.jpg");
      }
      logo.onload = () => {
        let qrImg = qrcodeEl.getElementsByTagName("img")[0];
        let canvas = qrcodeEl.getElementsByTagName("canvas")[0];
        canvas.style.display = "inline-block";
        let ctx = canvas.getContext("2d");
        ctx.drawImage(
          logo,
          (150 - 150 / 4) / 2,
          (150 - 150 / 4) / 2,
          150 / 4,
          150 / 4
        );
        qrImg.src = canvas.toDataURL();
        qrImg.style.display = "none";
        document.getElementById("qrcodeWrap").appendChild(qrcodeEl);
      };
    };

    const getCourseTime = (minutes) => {
      let courseTimesH = parseInt(minutes / 60);
      let courseTimesM = minutes - parseInt(minutes / 60) * 60;
      if (courseTimesH > 0) {
        if (courseTimesM > 0) {
          return courseTimesH + $t("Pub_Hour") + courseTimesM + $t("CM_Minute");
        }
        return courseTimesH + $t("Pub_Hour");
      }
      return courseTimesM + $t("CM_Minute");
    };

    provide("upData", {
      upScore: (data) => {
        state.scoreNum = data.scoreNum;
        state.notScoreNum = data.notScoreNum;
      },
    });

    const clickDownload = () => {
      let url = document.querySelector("#qrcode img").src;
      let name = dataInfo.value.courseName;
      if (state.modalType == "inQr") {
        name += `-${$t("train.sign_in_qr")}`;
        // 签到二维码
      } else {
        name += `-${$t("train.sign_out_qr")}`;
        // 签退二维码
      }
      fileDownload(url, name);
    };

    const goWxMeet = (meetId) => {
      if (wxwork.isWxWork) {
        wxwork.startMeeting(meetId);
      } else {
        proxy.$message.error(
          $t("train.please_use_enterprise_wechat_to_participate_in_meeting")
        );
        // 请用企业微信参与会议
      }
    };

    const goDingMeet = (meetData) => {
      proxy.$message.info($t("train.is_open_dingding_meeting"));
      // 正在打开钉钉会议
      window.open(meetData.url);
    };

    return {
      taskId,
      did,
      ...toRefs(state),
      platformConfig,
      dateFormat,
      loadding,
      dataInfo,
      moment,
      getCourseTime,
      showQr,
      QrModalCancel,
      clickDownload,
      goWxMeet,
      goDingMeet,
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  padding: 42px 0 100px;
  position: relative;
  .bg {
    .absolute-full();
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-image: url("~@/assets/image/train/top-bg.png");
    z-index: 1;
    overflow: hidden;
    min-height: 392px;
  }
  .content {
    .mixinWrap();
    .mixinFlex(space-between);
    .cover {
      z-index: 4;
      .mixinImgWrap(450px; 250px);
      border-radius: 6px;
    }
    .text {
      z-index: 4;
      width: calc(100% - 488px);
      color: #fff;
      line-height: 1.5;
      position: relative;
      h1 {
        width: calc(100% - 180px);
        font-size: 32px;
        font-weight: bold;
        line-height: 1.4;
        color: #fff;
        margin-bottom: 12px;
        word-break: break-all;
      }
      .leave {
        .icon-qj {
          display: inline-block;
          background-image: url(../../../assets/image/icon-qj.png);
          width: 27px;
          height: 27px;
          vertical-align: -6px;
          margin-right: 8px;
        }
        &.red {
          background: #e63450;
          border-color: #e63450;
          letter-spacing: 2px;
        }
        &.hui {
          background: #f5f5f5;
          border-color: #d9d9d9;
        }
        &.white {
          background: #bec8c8;
          border-color: #bec8c8;
        }
        width: 160px;
        height: 44px;
        line-height: 32px;
        border-radius: 4px;
        font-size: 20px;
        position: absolute;
        right: 0;
        top: 0;
      }
      p {
        margin-bottom: 12px;
        font-size: 20px;
        em {
          margin-left: 20px;
          vertical-align: -1px;
        }
        &.duration {
          color: #f5f5f5;
          font-size: 16px;
          .span:not(last-child) {
            margin-right: 20px;
          }
        }
      }
      .box {
        font-size: 18px;
        margin-top: 20px;
        height: 80px;
        .time {
          font-size: 24px;
        }
        .address {
          font-size: 16px;
          color: #f5f5f5;
        }
      }
    }
  }
}

.mian {
  position: relative;
  z-index: 1;
  padding-bottom: 64px;
  .mixinWrap();
  .mixinFlex(space-between);
  .detail {
    width: 902px;
    background-color: #fff;
    border-radius: 6px;
    margin-top: -56px;
    ::v-deep(.ant-tabs-bar) {
      margin: 0;
    }
    ::v-deep(.ant-tabs-nav-wrap) {
      padding: 0 30px;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab) {
      padding: 16px 0px;
      font-size: 16px;
      color: #999;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
      color: @color-theme;
    }
    ::v-deep(.ant-tabs-ink-bar) {
      background-color: @color-theme;
    }
  }
  .cards {
    width: 282px;
    height: 100%;
    margin-top: -56px;
    .item {
      border-radius: 6px;
      margin-bottom: 24px;
      color: #333333;
      background-color: #fff;
      padding: 24px 14px;
      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        color: #333;
        padding-left: 6px;
        border-left: 6px solid @color-theme;
      }
      &-part {
        padding-top: 12px;
        h5 {
          margin-bottom: 12px;
          font-size: 15px;
          font-weight: 600;
        }
        p {
          font-size: 13px;
          color: #666666;
          margin-bottom: 12px;
        }
      }
      .qr-btn {
        height: 64px;
        line-height: 62px;
        border: 1px solid @color-theme;
        border-radius: 6px;
        color: @color-theme;
        text-align: center;
        font-size: 22px;
        margin-top: 20px;
        cursor: pointer;
        user-select: none;
      }
      &.card-2 {
        .item-part {
          width: 50%;
          display: inline-block;
          p {
            font-size: 22px;
            color: #999;
            i {
              font-style: normal;
              font-size: 38px;
            }
          }
          &:nth-child(even) {
            p {
              color: @color-theme;
            }
          }
        }
      }
    }
  }
}
.qrcode-box {
  text-align: center;
  .qrcode {
    width: 150px;
    height: 150px;
    margin-bottom: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    box-sizing: content-box;
    display: inline-block;
  }
  .download {
    color: #2196f3;
    margin-left: 10px;
    cursor: pointer;
  }
  .date {
    padding: 3px 6px;
    background: #e6e6e6;
  }
}
</style>
